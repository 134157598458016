<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Configuraciones</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Responsables</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div class="font-medium text-3xl text-900">Administrar Responsables</div>
    </div>
    <div class="row mt-4">
      <DataTable
        ref="UsuariosTablaResponsable"
        dataKey="id"
        :value="usuarios"
        class="table table-striped table-bordered p-datatable-sm"
        :loading="loadingUsers"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Responsables"
        responsiveLayout="scroll"
      >
        <Column field="id" header="ID USUARIO" />
        <Column field="name" header="NOMBRE COMPLETO" />
        <Column field="email" header="CORREO ELECTRONICO" />
        <Column field="name" header="ROL ASIGNADO">
          <template #body="{ data }">
            <div>
              {{ data.roles[0].name }}
            </div>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="{ data }">
            <div>
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                v-tooltip.top="'Asignar Responsable'"
                @click="asignarResponsable(data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import UserService from "@/service/UserService.js";

export default {
  data() {
    return {
      usuarios: [],
      loadingUsers: false,
    };
  },
  userService: null,

  created() {
    this.userService = new UserService();
    this.cargarUsuarios();
  },

  methods: {
    cargarUsuarios() {
      this.loadingUsers = true;
      this.userService.getUsersAll().then((response) => {
        this.usuarios = response;
        this.loadingUsers = false;
      });
    },
    asignarResponsable(id) {
      this.$router.push({ name: "ResponsableAsignar", params: { id: id } });
    },
  },
};
</script>